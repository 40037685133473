import {
  firebaseApiKey,
  firebaseAppId,
  firebaseDomain,
  firebaseMeasurementId,
  firebaseMessageSenderId,
  firebaseProjectId,
  firebaseStorageBucket,
  firebaseVapidKey,
} from "config";
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from "firebase/messaging";
import { axios } from "./axios";
import { AxiosError } from "axios";

const firebaseConfig = {
  apiKey: firebaseApiKey,
  authDomain: firebaseDomain,
  projectId: firebaseProjectId,
  storageBucket: firebaseStorageBucket,
  messagingSenderId: firebaseMessageSenderId,
  appId: firebaseAppId,
  measurementId: firebaseMeasurementId,
};

// Initialize Firebase
const initializeFirebase = async () => {
  try {
    const hasFirebaseSupport = await isSupported();
    if (!hasFirebaseSupport) {
      console.error("Firebase Messaging is not supported in this browser.");
      return null;
    }

    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);
    return messaging;
  } catch (error) {
    console.error("Error initializing Firebase", error);
    return null;
  }
};

export const requestPermission = async () => {
  const messaging = await initializeFirebase();
  if (!messaging) return;

  console.log("Requesting permission...");

  try {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      console.log("Notification permission granted.");

      const token = await getToken(messaging, {
        vapidKey: firebaseVapidKey,
      }).catch((err) => {
        console.log(err);
      });

      if (token) {
        try {
          const response = await axios.post("/fcm/register", {
            fcmToken: token,
          });
        } catch (error) {
          if (error instanceof AxiosError) {
            console.error(error.response?.data.error);
          }
          return error as any;
        }
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    } else {
      console.log("Permission denied");
    }
  } catch (err) {
    console.error("Error requesting permission", err);
  }
};

export const onMessages = async (callback: any) => {
  const messaging = await initializeFirebase();
  if (messaging) {
    // Return the unsubscribe function provided by onMessage
    return onMessage(messaging, (payload) => {
      callback(payload);
    });
  }
};
