import * as React from "react";
import * as Icon from "react-icons/fa6";
import { CiLogout } from "react-icons/ci";
import { MdMenu } from "react-icons/md";

import styles from "./MainLayout.module.scss";
import navigationData from "./navigationData";

import logo from "assets/sidebar logo.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button, Drawer, Badge } from "@mui/material";
import storage from "utils/storage";
import {
  NextWeekShiftsContext,
  NotificationsCountContext,
} from "routes/protected";

export interface NavigationProps {
  notifications: number;
}
const SideNavigation = (props: NavigationProps) => {
  const navigate = useNavigate();

  const logout = async () => {
    await storage.clearAllCaches();
    storage.clearAll();
    window.location.href = "/auth/login";
  };

  const navigateToHome = () => {
    navigate("/main/home");
  };

  return (
    <div className={styles.sidenavContainer}>
      <img
        className="p-4 cursor-pointer"
        src={logo}
        onClick={navigateToHome}
        alt="logo"
      />
      <div className={styles.sidenavItemContainer}>
        {navigationData.map((navItem, index) => (
          <NavLink
            key={navItem.name}
            to={navItem.to}
            className={styles.sidenavItemWrapper}
          >
            {navItem.name === "My Inbox" ? (
              <Badge
                badgeContent={props.notifications}
                color="error"
                className="drawer-item-image"
              >
                <navItem.icon />
              </Badge>
            ) : (
              <div className="drawer-item-image">
                <navItem.icon />
              </div>
            )}
            <div className="sidenav-item-title">{navItem.name}</div>
          </NavLink>
        ))}
      </div>

      <div className={styles.logoutContainer}>
        <NavLink
          className={styles.sidenavItemWrapper}
          to="/auth/login"
          onClick={logout}
        >
          <div className="sidenav-item-image">
            <CiLogout />
          </div>
          <div className="sidenav-item-title"> Log out </div>
        </NavLink>
        <div className={styles.version}>Version No: 1.04-beta</div>
      </div>
    </div>
  );
};

const MobileTabs = (props: NavigationProps) => {
  const location = useLocation();
  React.useEffect(() => {
    setOpen(false);
  }, [location]);
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const logout = async () => {
    storage.clearAll();
    window.location.href = "/auth/login";
  };

  const DrawerList = (
    <div className={styles.drawerItemContainer}>
      {navigationData
        .filter((navItem) => !navItem.excludeOnMobile)
        .map((navItem, index) => (
          <NavLink
            key={navItem.name}
            to={navItem.to}
            className={styles.drawerItemWrapper}
            onClick={toggleDrawer(false)}
          >
            {navItem.name === "My Inbox" ? (
              <Badge
                badgeContent={props.notifications}
                color="error"
                className="drawer-item-image"
              >
                <navItem.icon />
              </Badge>
            ) : (
              <div className="drawer-item-image">
                <navItem.icon />
              </div>
            )}
            <div className="drawer-item-title">{navItem.name}</div>
          </NavLink>
        ))}

      <div className={styles.logoutContainer}>
        <NavLink
          className={styles.drawerItemWrapper}
          to="/auth/login"
          onClick={logout}
        >
          <div className="drawer-item-image">
            <CiLogout />
          </div>
          <div className="drawer-item-title"> Log out </div>
        </NavLink>
        <div className={styles.version}>Version No: 1.04-beta</div>
      </div>
    </div>
  );

  return (
    <div className={styles.mobileTabsContainer}>
      <NavLink key={"Home"} to="./home" className={styles.mobileTabsWrapper}>
        <div className="mobile-tab-image">
          <Icon.FaHouse />
        </div>
        <div className="mobile-tab-title">Home</div>
      </NavLink>
      <div className="flex flex-1">
        <Button
          color="secondary"
          onClick={toggleDrawer(true)}
          className={styles.mobileTabsWrapper}
        >
          <div className="mobile-tab-image">
            <MdMenu />
          </div>
          <div className="mobile-tab-title normal-case font-normal">Menu</div>
        </Button>
        <Drawer open={open} onClose={toggleDrawer(false)} anchor="bottom">
          {DrawerList}
        </Drawer>
      </div>
      <NavLink
        key={"AccountSettings"}
        to="./accountSettings"
        className={styles.mobileTabsWrapper}
      >
        <div className="mobile-tab-image">
          <Icon.FaUser />
        </div>
        <div className="mobile-tab-title">Account</div>
      </NavLink>
      <NavLink
        key={"myInbox"}
        to="./myInbox"
        className={styles.mobileTabsWrapper}
      >
        <Badge
          badgeContent={props.notifications}
          color="error"
          className="mobile-tab-title"
        >
          <Icon.FaInbox />
        </Badge>
        <div className="mobile-tab-title">Inbox</div>
      </NavLink>
    </div>
  );
};

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const { notifications, setNotifications } = React.useContext(
    NotificationsCountContext
  );

  const { upcomingWeekShifts, setUpcomingWeekShifts } = React.useContext(
    NextWeekShiftsContext
  );

  const unreadNotifications = notifications.filter(
    (notification) => !notification.completedUser?.id
  );

  const pendingShift = upcomingWeekShifts.filter(
    (upcomingWeekShift) => upcomingWeekShift.confirmed === null
  );

  const combinedNotifications = [...unreadNotifications, ...pendingShift]
    .length;
  return (
    <div className="flex h-[90vh] lg:h-screen">
      <div className="hidden lg:block">
        <SideNavigation notifications={combinedNotifications} />
      </div>
      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        {children}
      </main>
      <div className="lg:hidden">
        <MobileTabs notifications={combinedNotifications} />
      </div>
    </div>
  );
};
