import * as Icon from "react-icons/fa6";
import { PiCurrencyGbpBold } from "react-icons/pi";
import { BsPersonFillExclamation } from "react-icons/bs";
import { NavigationItem } from "../types";
import { HiDocumentSearch } from "react-icons/hi";

const navigationData: NavigationItem[] = [
  { name: "Home", to: "./home", icon: Icon.FaHouse },
  {
    name: "My Inbox",
    to: "./myInbox",
    icon: Icon.FaInbox,
    excludeOnMobile: true,
  },
  { name: "My Planner", to: "./myPlanner", icon: Icon.FaCalendarDays },
  { name: "My Pay", to: "./myPay", icon: PiCurrencyGbpBold },
  { name: "My Job", to: "./myJob", icon: Icon.FaBriefcase },
  {
    name: "My Documents",
    to: "./myDocuments",
    icon: HiDocumentSearch,
  },
  { name: "About Me", to: "./AboutMe", icon: Icon.FaUser },
  { name: "Help", to: "./help", icon: Icon.FaCircleInfo },
  {
    name: "Account & Preferences",
    to: "./accountSettings",
    icon: Icon.FaGear,
    excludeOnMobile: true,
  },
  // { name: "Landing Video", to: "./landingVideo", icon: Icon.FaVideo },
];

export default navigationData;
