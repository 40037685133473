import Swal from "sweetalert2";

type ToastPosition =
  | "top"
  | "top-start"
  | "top-end"
  | "center"
  | "center-start"
  | "center-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end";

type ToastType = "success" | "error" | "warning";

const defaultToastPosition: ToastPosition = "top-end"; // Default position

const Toast = {
  success: (message: string, position?: ToastPosition) => {
    showToast(message, "success", position);
  },

  danger: (message: string, position?: ToastPosition) => {
    showToast(message, "error", position);
  },

  warning: (message: string, position?: ToastPosition) => {
    showToast(message, "warning", position);
  },
};

const showToast = (
  title: string,
  type: ToastType,
  position?: ToastPosition
) => {
  Swal.fire({
    title,
    icon: type,
    position: position || defaultToastPosition,
    toast: true,
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });
};

export default Toast;
