import { axios } from "lib/axios";
import { SiteOptions, Sites } from "../types";

export const getSites = (): Promise<Sites> => {
  return axios.get("/site/sites");
};

export const getSiteOptions = (): Promise<SiteOptions> => {
  return axios.get("/site/options");
};
