import { AxiosError } from "axios";
import {
  ShiftsSearch,
  ShiftsSearchResponse,
  ShiftEnquiry,
  ShiftEnquiryResponse,
  SearchWeekShiftResponse,
  MissingShiftEnquiry,
} from "../types";
import { axios } from "lib/axios";
import toast from "components/Elements/Toast/Toast";
import alert from "components/Elements/Alert/Alert";

export const searchShifts = async (
  data: ShiftsSearch
): Promise<ShiftsSearchResponse> => {
  try {
    const response = (await axios.post(
      "/shift/shifts",
      data
    )) as ShiftsSearchResponse;

    return response;
  } catch (error) {
    if (error instanceof AxiosError) {
      console.error(error.response?.data.error);
    }
    throw error;
  }
};

export const shiftEnquiry = async (
  data: ShiftEnquiry
): Promise<ShiftEnquiryResponse> => {
  try {
    const response = (await axios.post(
      "/shift/enquiry",
      data
    )) as ShiftEnquiryResponse;
    if (response.status === "OK") {
      alert.success(response?.notes);
    }
    return response;
  } catch (error) {
    if (error instanceof AxiosError) {
      toast.danger(error.response?.data.notes);
    }

    return error as any;
  }
};

export const missingShiftEnquiry = async (
  data: MissingShiftEnquiry
): Promise<ShiftEnquiryResponse> => {
  try {
    const response = (await axios.post(
      "/shift/sendMissingShifts",
      data
    )) as ShiftEnquiryResponse;
    if (response.status === "OK") {
      alert.success(response?.notes);
    }
    return response;
  } catch (error) {
    if (error instanceof AxiosError) {
      toast.danger(error.response?.data.message);
    }

    return error as any;
  }
};

export const getWeekShifts = async (
  data: ShiftsSearch
): Promise<SearchWeekShiftResponse> => {
  try {
    const response = (await axios.post(
      "/shift/shiftWeeklySummary",
      data
    )) as SearchWeekShiftResponse;

    return response;
  } catch (error) {
    if (error instanceof AxiosError) {
      console.error(error.response?.data.error);
    }
    throw error;
  }
};

export const confirmShift = async (data: {
  shiftDriverId: string;
  confirm: string | null;
}): Promise<any> => {
  try {
    const response = (await axios.put(
      "/shift/set_shift_confirmation",
      data
    )) as any;
    return response;
  } catch (error) {
    if (error instanceof AxiosError) {
      console.error(error.response);
    }
    throw error;
  }
};
