export const API_URL = process.env.REACT_APP_API_URL as string;
export const PHONE_NUMBER = process.env.REACT_APP_PHONE_NUMBER;
export const getAddressApi = process.env.REACT_APP_GETADDRESSAPI;
export const getAddresKey = process.env.REACT_APP_GETADDRESSKEY;
export const getDocuSign = process.env.REACT_APP_DOCUSIGNING;

// FIREBASE CONFIG
export const firebaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY;
export const firebaseDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const firebaseProjectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const firebaseStorageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const firebaseMessageSenderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const firebaseAppId = process.env.REACT_APP_FIREBASE_APP_ID;
export const firebaseMeasurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
export const firebaseVapidKey = process.env.REACT_APP_FIREBASE_VAPID_KEY;
