import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#509dc5 ",
    },
    secondary: {
      main: "#1a5474",
    },
    info: {
      main: "#FFFF",
    },
    warning: {
      main: "#ef6c00",
    },
  },
  typography: {
    fontFamily: [
      "ui-sans-serif",
      "system-ui",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ].join(","),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: "500",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: "#509dc5",
          color: "white",
          fontWeight: "500",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: "1rem",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: "48px",
          maxHeight: "68px",
          borderRadius: "1rem",
          backgroundColor: "#022b39",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "0.5rem 0.5rem",
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "1rem !important",
        },
      },
    },
  },
});

export default theme;
