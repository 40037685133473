import {
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { IoClose } from "react-icons/io5";
import styles from "./Webchat.module.scss";
import { FaUser } from "react-icons/fa6";
import logo from "assets/images/12002_Challenge-trg Group_PRIMARY 2.svg";
import helpIcon from "assets/images/support-chat/Help.svg";
import sendIcon from "assets/images/support-chat/Send.svg";
import arrowIcon from "assets/images/support-chat/Arrow.svg";
import {
  Conversation,
  Client as ConversationsClient,
} from "@twilio/conversations";
import {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useContext,
} from "react";
import { PHONE_NUMBER } from "config";
import { BsWhatsapp } from "react-icons/bs";
import { IoIosChatbubbles } from "react-icons/io";
import { MdSms, MdPhoneForwarded } from "react-icons/md";
import { format } from "date-fns";
import { useForm } from "react-hook-form";
import { getChatToken } from "./api/chat-api";
import { ColleagueDetailsContext } from "routes/protected";
import Draggable from "react-draggable";
import { GenerateTokenParams } from "./types/chat-types";

export const Webchat = () => {
  const { register, handleSubmit, reset } = useForm();

  const { colleagueDetails, setColleagueDetails } = useContext(
    ColleagueDetailsContext
  );
  const [conversationsClient, setConversationsClient] =
    useState<ConversationsClient>();
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [particiapnt, setParticipant] = useState<string | null>(null);
  const [chatIndex, setChatIndex] = useState<number | null>(null);
  const [showChatContainer, setShowChatContainer] = useState(false);
  const [loadingChat, setLoadingChat] = useState(false);
  const [failedToLoadChat, setFailedToLoadChat] = useState(false);
  const [isChatInitialized, setIsChatInitialized] = useState(false);
  const [isConversationJoined, setIsConversationJoined] = useState(false);
  const [hasConversationeEnded, setHasConversationEnded] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const participantIdentitiesRef = useRef("");

  const [messages, setMessages] = useState([
    {
      author: "Bot",
      body: `Hello ${colleagueDetails.forename}, how can we help you today?`,
      dateCreated: new Date(),
    },
  ]);

  const [anchorEl, setAnchorEl] = useState<null | any>(null);
  const nodeRef = useRef(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrag = (e: any) => {
    if (e instanceof TouchEvent) {
      e.preventDefault();
      setAnchorEl(null);
    }
  };

  const handleMouseDown = (e: any) => {
    if (e instanceof TouchEvent) {
      setAnchorEl(e.currentTarget);
      e.preventDefault();
    }
  };

  const initChat = async () => {
    const storedToken = localStorage.getItem("chatToken");
    const storedIdentity = localStorage.getItem("chatIdentity");
    const storedSid = localStorage.getItem("chatSid");
    let token: string;
    let currentParticipant: string;

    if (storedToken && storedIdentity && storedSid) {
      token = storedToken;
      currentParticipant = storedIdentity;
      setParticipant(currentParticipant);
    } else {
      try {
        const response = (await getChatToken()) as GenerateTokenParams;
        token = response.token.token;
        localStorage.setItem("chatToken", response.token.token);
        localStorage.setItem("chatIdentity", response.token.identity);
        currentParticipant = response.token.identity;
        setParticipant(currentParticipant);
      } catch (error) {
        console.error("Error fetching chat token", error);
        return;
      }
    }

    try {
      const conversationsClient = new ConversationsClient(token);
      setConversationsClient(conversationsClient);
      setIsConversationJoined(false);
      if (conversationsClient) {
        conversationsClient.on("stateChanged", (state) => {
          if (state === "failed") {
            setFailedToLoadChat(true);
          }
        });
        conversationsClient.on("connectionStateChanged", (state) => {
          if (state === "connecting") {
            console.log("connecting....");
            setLoadingChat(true);
            setIsChatInitialized(true);
            setShowChatContainer(true);
            setAnchorEl(null);
          } else if (state === "connected") {
            setFailedToLoadChat(false);
            setLoadingChat(false);
            setIsChatInitialized(true);
            setShowChatContainer(true);
            setAnchorEl(null);

            console.log("Connected to Twilio Conversations");
          } else if (state === "disconnecting") {
            console.log("Disconnecting from Twilio Conversations…");
          } else if (state === "disconnected") {
            console.log("Disconnected from Twilio Conversations");
          } else if (state === "denied") {
            clearChatData();
            setFailedToLoadChat(true);
            console.log("Failed to connect to Twilio Conversations");
          }
        });
        conversationsClient.on("conversationJoined", async (conversation) => {
          if (isConversationJoined) {
            return;
          }
          setHasConversationEnded(false);
          setLoadingMessages(true);
          setMessages([
            {
              author: "Bot",
              body: `Hello ${colleagueDetails.forename}, how can we help you today?`,
              dateCreated: new Date(),
            },
          ]);
          setIsConversationJoined(true);

          participantIdentitiesRef.current = currentParticipant;

          const messages = await (conversation as any).getMessages();
          const messageCount = messages.items.length;
          let completedCount = 0;

          if (messageCount === 0) {
            setLoadingMessages(false);
          }

          messages.items.forEach(async (message: any) => {
            getPreviousMessages(message);
            completedCount++;

            if (completedCount === messageCount) {
              setLoadingMessages(false);
            }
          });
          setConversation(conversation);
          setIsChatInitialized(true);
        });

        conversationsClient.on("conversationLeft", (data: any) => {
          clearChatData();
        });

        conversationsClient.on("typingStarted", async (typing: any) => {
          setIsTyping(true);
        });

        conversationsClient.on("typingEnded", () => {
          setIsTyping(false);
        });

        conversationsClient.on("messageAdded", handleMessageAdded);
      }
    } catch (error) {
      console.error("Error initializing ConversationsClient", error);
    }
  };

  const handleMessageAdded = (message: any) => {
    const newMessage = {
      author: message.state.author,
      body: message.state.body,
      dateCreated: message.state.timestamp,
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  const getPreviousMessages = (message: any) => {
    const newMessage = {
      author: message.state.author,
      body: message.state.body,
      dateCreated: message.state.timestamp,
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  const sendMessage = async (message: any) => {
    if (conversation && message.sentMessage.length > 0) {
      try {
        let preparedMessage = conversation.prepareMessage();

        preparedMessage.setBody(message.sentMessage);
        preparedMessage.setAttributes({
          author: message.author,
        });

        await preparedMessage.build().send();
        reset();
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  const closeChatContainer = () => {
    conversationsClient?.removeAllListeners();
    if (hasConversationeEnded) {
      clearChatData();
    }
    setShowChatContainer(false);
    setAnchorEl(null);
  };

  const createNewChatAfterClose = () => {
    clearChatData();

    initChat();
  };

  const clearChatData = () => {
    setConversation(null);
    setIsChatInitialized(false);
    setIsConversationJoined(false);
    setLoadingChat(false);
    setLoadingMessages(false);
    localStorage.removeItem("chatToken");
    localStorage.removeItem("chatIdentity");
    localStorage.removeItem("chatSid");
    conversationsClient?.removeAllListeners();
  };

  useEffect(() => {
    // Update the chat index when messages length changes
    if (messages.length > 1) {
      setChatIndex(messages.length);
      const getLastMessage = document.getElementById(
        `chat-message-${chatIndex}`
      );
      if (getLastMessage) {
        getLastMessage.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }
  }, [messages, chatIndex]);

  useLayoutEffect(() => {}, [messages, chatIndex]);

  return (
    <div>
      <div className={styles.chatIconContainer}>
        <div className={styles.chatContainer}>
          <div className={styles.chatPopup} hidden={!showChatContainer}>
            <div className={styles.chatContainerHeader}>
              <div className={styles.chatHeader}>
                <img
                  className={styles.imageChatHeader}
                  alt="chat-header"
                  src={logo}
                />
                <div className={styles.textChatHeaderContainer}>
                  <div className={styles.textChatHeader}>Support Chat</div>
                </div>
                <IoClose
                  className="cursor-pointer absolute top-0 right-0 h-5 w-5"
                  onClick={closeChatContainer}
                >
                  close
                </IoClose>
              </div>
              {!loadingMessages && !loadingChat && !failedToLoadChat ? (
                <div className={styles.chatContainerInside}>
                  <div className={styles.welcomeMessageContainer}>
                    <div className={styles.welcomeMessage}>
                      <div className="welcome-message-icon">
                        <img
                          alt="help-icon"
                          className={styles.helpIcon}
                          src={helpIcon}
                        />
                      </div>
                      <div className="welcome-message-text">
                        <span>Welcome to customer service</span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.dateMessageContainer}>
                    <div className={styles.dateMessage}>
                      <div className={styles.dateMessageSeperator}>
                        <hr className={styles.seperator} />
                        <div className={styles.date}>TODAY</div>
                        <hr className={styles.seperator} />
                      </div>
                    </div>
                  </div>
                  <div className={styles.chatMessagesContainer}>
                    {messages.map((message, index) => (
                      <div
                        className={
                          message.author === particiapnt
                            ? styles.messageContentContainerSent
                            : styles.messageContentContainerReceived
                        }
                        key={index}
                      >
                        <div className={styles.messageAvatarContainer}>
                          <div className={styles.messageAvatar}>
                            <div className={styles.avatarIcon}>
                              <FaUser />
                            </div>
                          </div>
                        </div>
                        <div className={styles.messageBubbleContainer}>
                          <div className={styles.messageBubble}>
                            <div className={styles.messageBubbleHeader}>
                              <div className={styles.messageBubbleUsername}>
                                {message.author === particiapnt
                                  ? `${colleagueDetails.forename}`
                                  : message.author}
                              </div>
                              <div className={styles.messageBubbleTime}>
                                {format(message.dateCreated, "dd/MM/yy, HH:mm")}
                              </div>
                            </div>
                            <div
                              className={`${styles.messageBubbleBody} chat-message`}
                              id={`chat-message-${index}`}
                            >
                              {message.body}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : failedToLoadChat ? null : (
                <div className={styles.chatLoader}>
                  <CircularProgress size={"6rem"}></CircularProgress>
                  Loading chat...
                </div>
              )}

              {failedToLoadChat ? (
                <div className="failed-to-load-chat-container">
                  <div className={styles.failedToLoadMessage}>
                    Something went wrong. Couldn't load conversation. Please try
                    again later.
                  </div>
                </div>
              ) : null}
              {isTyping ? (
                <Typography paddingX={"1rem"} fontWeight={"300"}>
                  Agent is typing...
                </Typography>
              ) : null}
              {!hasConversationeEnded && !failedToLoadChat ? (
                <div>
                  <div className={styles.chatInputContainer}>
                    <div className={styles.chatInputDefault}>
                      <form
                        onSubmit={handleSubmit(sendMessage)}
                        className={styles.chatInput}
                      >
                        {/* <div className={styles.chatInputArea}> */}
                        <TextField
                          rows="1"
                          variant="outlined"
                          placeholder="Type message"
                          // onKeyUp={sendMessage}
                          {...register("sentMessage")}
                        />
                        {/* </div> */}
                        <div className={styles.sendMessage}>
                          <Button
                            className={styles.sendMessageButton}
                            type="submit"
                          >
                            <img
                              className={styles.sendIcon}
                              alt="send"
                              src={sendIcon}
                            />
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              ) : null}
              {hasConversationeEnded ? (
                <div
                  className={styles.chatClosedContainer}
                  onClick={createNewChatAfterClose}
                >
                  Thanks for chatting with us! If you have any more questions,
                  feel free to reach out again.
                  <Button color="primary">Chat with us!</Button>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {showChatContainer ? (
          <Button
            variant="contained"
            onClick={closeChatContainer}
            className={styles.chatButtonClose}
          >
            <img alt="help" className={styles.helpIcon} src={arrowIcon} />
          </Button>
        ) : (
          <Draggable
            nodeRef={nodeRef}
            onDrag={handleDrag}
            onMouseDown={handleMouseDown}
          >
            <div ref={nodeRef} className={styles.chatOpenButton}>
              <Button
                color="primary"
                className={styles.chatButton}
                variant="contained"
                onClick={handleClick}
              >
                <div className={styles.chatButtonHeader}>
                  <span>Need Help?</span>
                  <img alt="help" className={styles.helpIcon} src={helpIcon} />
                </div>
              </Button>
            </div>
          </Draggable>
        )}
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <div className="py-2">
            <IoClose
              onClick={handleClose}
              className="cursor-pointer absolute top-0 right-0 h-5 w-5"
            />
          </div>
          <div>
            <MenuItem>
              <Button
                className="w-full"
                color="primary"
                variant="contained"
                startIcon={<IoIosChatbubbles />}
                onClick={initChat}
              >
                Live Help (Webchat)
              </Button>
            </MenuItem>
            <MenuItem>
              <Button
                className="w-full"
                color="secondary"
                variant="contained"
                startIcon={<BsWhatsapp />}
                href={`https://wa.me/${PHONE_NUMBER}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                WhatsApp
              </Button>
            </MenuItem>

            <MenuItem>
              <Button
                className="w-full"
                color="primary"
                variant="contained"
                startIcon={<MdSms />}
                href={`sms:${PHONE_NUMBER}`}
              >
                Text (SMS)
              </Button>
            </MenuItem>

            <MenuItem>
              <Button
                className="w-full"
                color="secondary"
                variant="contained"
                startIcon={<MdPhoneForwarded />}
                href={`tel:${PHONE_NUMBER}`}
              >
                Call Us (Voice call)
              </Button>
            </MenuItem>
          </div>
        </Menu>
      </div>
    </div>
  );
};
