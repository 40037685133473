import React, { createContext, useContext, useState } from "react";

// Create the context
const GlobalLoadingContext = createContext<
  | {
      globalLoading: boolean;
      setGlobalLoading: React.Dispatch<React.SetStateAction<boolean>>;
    }
  | undefined
>(undefined);

// Provider component
export const GlobalLoadingProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [globalLoading, setGlobalLoading] = useState<boolean>(false);

  return (
    <GlobalLoadingContext.Provider value={{ globalLoading, setGlobalLoading }}>
      {children}
    </GlobalLoadingContext.Provider>
  );
};

// Custom hook for using the context
export const useGlobalLoading = () => {
  const context = useContext(GlobalLoadingContext);
  if (context === undefined) {
    throw new Error(
      "useGlobalLoading must be used within a GlobalLoadingProvider"
    );
  }
  return context;
};
