import * as React from "react";
import styles from "./ContentLayout.module.scss";
import { Head } from "components/Head";

type ContentLayoutProps = {
  children: React.ReactNode;
  title: string;
};

export const ContentLayout = ({ children, title }: ContentLayoutProps) => {
  return (
    <>
      <Head title={title} />
      <div className={`${styles.contentContainer} pt-4 px-4 mb-14 lg:pt-4 lg:px-8 lg:mb-0`}>
        <div className={styles.contentTitle}>
          <h1>{title}</h1>
        </div>
        <div className={styles.contentBody}>{children}</div>
      </div>
    </>
  );
};
