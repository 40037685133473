import { SiteAvailability } from "features/colleague-portal/myJob/types/sites";

const storage = {
  getToken: () => {
    return window.localStorage.getItem("token");
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`token`);
  },
  getBookingType: () => {
    return window.localStorage.getItem("bookingType");
  },
  setBookingType: (bookingType: string) => {
    window.localStorage.setItem("bookingType", bookingType);
  },
  clearBookingType: () => {
    window.localStorage.removeItem(`bookingType`);
  },
  // getSiteAvailability: () => {
  //   return {
  //     siteAvailability: window.localStorage.getItem("siteAvailability"),
  //     siteAvailabilityNoticePeriod: window.localStorage.getItem(
  //       "siteAvailabilityNoticePeriod"
  //     ),
  //     siteAvailabilityTimeList: JSON.parse(
  //       window.localStorage.getItem("siteAvailabilityTimeList") || "{}"
  //     ),
  //   };
  // },

  getSiteAvailability: () => {
    return JSON.parse(window.localStorage.getItem("siteAvailability") || "{}");
  },

  // setSiteAvailability: (
  //   siteAvailability: string,
  //   siteAvailabilityNoticePeriod: string,
  //   siteAvailabilityTimeList: object // Accepting an object for time list
  // ) => {
  //   window.localStorage.setItem("siteAvailability", siteAvailability);
  //   window.localStorage.setItem(
  //     "siteAvailabilityNoticePeriod",
  //     siteAvailabilityNoticePeriod
  //   );
  //   window.localStorage.setItem(
  //     "siteAvailabilityTimeList",
  //     JSON.stringify(siteAvailabilityTimeList) // Storing time list as a JSON string
  //   );
  // },

  setSiteAvailability: (siteAvailability: SiteAvailability) => {
    window.localStorage.setItem(
      "siteAvailability",
      JSON.stringify(siteAvailability)
    );
  },

  clearSiteAvailability: () => {
    window.localStorage.removeItem("siteAvailability");
  },

  clearAll: () => {
    window.localStorage.clear();
  },
  clearAllCaches: async () => {
    if ("caches" in window) {
      const cacheNames = await caches.keys();
      await Promise.all(
        cacheNames.map((cacheName) => caches.delete(cacheName))
      );
      console.log("All caches cleared");
    }
  },
};

export default storage;
