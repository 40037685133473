import { axios } from "lib/axios";
import { GenerateTokenParams } from "../types/chat-types";

export const getChatToken = (): Promise<GenerateTokenParams> => {
  return axios.get("/live_chat/generateToken");
};

// import axios from "axios";
// import { GenerateTokenParams } from "../types/chat-types";

// export const getChatToken = (): Promise<GenerateTokenParams> => {
//   return axios.post("http://localhost:3001/initWebchat", {
//     identity: "Ismail",
//     customerFriendlyName: "Ismail",
//     CustomerFriendlyName: "Ismail",
//   });
// };
