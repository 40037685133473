import { useNavigate, useRoutes } from "react-router-dom";
import { publicRoutes } from "./public";
import { protectedRoutes } from "./protected";
import storage from "../utils/storage";
import { useEffect } from "react";

export const AppRoutes = () => {
  const navigate = useNavigate();
  const token = storage.getToken();

  const routes = token ? protectedRoutes : publicRoutes;

  const element = useRoutes([...routes]);

  useEffect(() => {
    if (token && !element) {
      navigate("/main/home");
    } else if (!token && !element) {
      navigate("/auth/login");
    }
  }, [token, element, navigate]);

  return <>{element}</>;
};
