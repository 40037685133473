import SelectInput from "@mui/material/Select/SelectInput";
import Swal from "sweetalert2";

type AlertType = "success" | "error" | "warning" | "info";

type AlertPosition =
  | "top"
  | "top-start"
  | "top-end"
  | "center"
  | "center-start"
  | "center-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end";

const defaultAlertPosition: AlertPosition = "center"; // Default position

type FileUploadCallback = (file: File) => void;

type ConfirmCancelCallback = (onAction: boolean) => void;

type ActionCallBack = (actionType: string) => void;

const Alert = {
  success: (message: string, title?: string) => {
    showAlert(message, title, "success");
  },

  error: (message: string, title?: string) => {
    showAlert(message, title, "error");
  },

  warning: (message: string, title?: string) => {
    showAlert(message, title, "warning");
  },

  info: (message: string, title?: string) => {
    showAlert(message, title, "info");
  },

  upload: (message: string, onFileUpload: FileUploadCallback) => {
    showFileUploadAlert(message, onFileUpload);
  },

  cancelConfirm: (message: string, onAction: ConfirmCancelCallback) => {
    cancelConfirmAlert(message, onAction);
  },
  selectOption: (
    message: string,
    options: string[],
    onConfirm: ActionCallBack,
    onCancel: () => void
  ) => {
    actionAlert(message, options, onConfirm, onCancel);
  },
  input: (message: string, placeholder: string, onInput: ActionCallBack) => {
    inputAlert(message, placeholder, onInput);
  },
};

const showAlert = (title: string, text?: string, type?: AlertType) => {
  Swal.fire({
    title,
    text,
    icon: type,
    position: defaultAlertPosition,
    showConfirmButton: true,
    confirmButtonColor: "#509dc5",
  });
};

const showFileUploadAlert = (
  title: string,
  onFileUpload: FileUploadCallback,
  position?: AlertPosition
) => {
  Swal.fire({
    title,
    html: `
      <input type="file" id="fileInput" style="border: 3px solid #509dc5" />
    `,
    icon: "info",
    position: position || defaultAlertPosition,
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonColor: "#509dc5",
    preConfirm: () => {
      const fileInput = document.getElementById(
        "fileInput"
      ) as HTMLInputElement;
      if (fileInput.files!.length > 0) {
        const file = fileInput.files![0];
        onFileUpload(file);
      } else {
        Swal.showValidationMessage("Please select a file");
      }
    },
  });
};

const cancelConfirmAlert = (
  title: string,
  onAction: ConfirmCancelCallback,
  position?: AlertPosition
) => {
  Swal.fire({
    title,

    icon: "info",
    position: position || defaultAlertPosition,
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonColor: "#509dc5",
    preConfirm: () => {
      onAction(true);
    },
  });
};

const actionAlert = (
  title: string,
  options: string[],
  onAction: (selectedOption: string) => void,
  onCancel?: () => void,
  position?: AlertPosition
) => {
  Swal.fire({
    title,
    icon: "info",
    position: position || defaultAlertPosition,
    showCancelButton: true,
    showConfirmButton: true,
    confirmButtonColor: "#509dc5",
    input: "select",
    inputOptions: options.reduce((acc: any, option: any) => {
      acc[option] = option;
      return acc;
    }, {}),
    preConfirm: (selectedOption: string) => {
      onAction(selectedOption);
    },
  }).then((result) => {
    if (result.isDismissed && onCancel) {
      onCancel();
    }
  });
};

const inputAlert = (
  title: string,
  inputPlaceholder: string,
  onAction: ActionCallBack,
  position?: AlertPosition
) => {
  Swal.fire({
    title: title,
    input: "text",
    inputPlaceholder: inputPlaceholder,
    icon: "info",
    position: position || defaultAlertPosition,
    showCancelButton: true,
    confirmButtonColor: "#509dc5",
    preConfirm: (inputValue) => {
      onAction(inputValue);
    },
  });
};

export default Alert;
