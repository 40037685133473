import Axios, { AxiosHeaders } from "axios";
import storage from "../utils/storage";
import { API_URL } from "../config";

function authRequestInterceptor(config: any): any {
  const token = storage.getToken();
  if (token) {
    (config.headers as AxiosHeaders).set("Authorization", `Bearer ${token}`);
  }

  return config;
}

export const axios = Axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    if (response.data.token && !response.data.token.token) {
      localStorage.setItem("token", response.data.token);
    }
    return response.data;
  },
  (error) => {
    if (error.response.status === 403 || error.response.status === 401) {
      if (error.response.status === 403) {
        window.location.reload();
      }
      localStorage.clear();
    }

    return Promise.reject(error);
  }
);
