import { axios } from "lib/axios";
import {
  ColleagueAlertResponse,
  DismissColleagueAlertResponse,
} from "../types";
import { AxiosError } from "axios";

export const getColleagueAlerts = (): Promise<ColleagueAlertResponse> => {
  return axios.get("/alert");
};

export const dismissColleagueAlert = async (
  multiSelectArray: number[]
): Promise<DismissColleagueAlertResponse> => {
  try {
    const response = (await axios.put(`/alert/dismiss`, {
      multiSelectArray: multiSelectArray,
    })) as DismissColleagueAlertResponse;
    return response;
  } catch (error) {
    throw error;
  }
};

export const removeColleagueAlert = async (
  multiSelectArray: number[]
): Promise<DismissColleagueAlertResponse> => {
  try {
    const response = (await axios.put(`/alert/remove`, {
      multiSelectArray: multiSelectArray,
    })) as DismissColleagueAlertResponse;
    return response;
  } catch (error) {
    throw error;
  }
};
