import Toast from "components/Elements/Toast/Toast";
import { useState, useEffect, useRef } from "react";

export const useOnlineStatus = () => {
  // Initialize state with the current online status
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  // Ref to keep track of the previous online status
  const wasOnline = useRef(!navigator.onLine);

  useEffect(() => {
    const updateOnlineStatus = () => {
      const currentStatus = navigator.onLine;
      setIsOnline(currentStatus);
      // Show toast if the previous status was offline and now it's online

      if (currentStatus) {
        Toast.success("Reestablished Connection, you are now Online!");
      }
      // Show toast if the previous status was online and now it's offline
      if (!currentStatus) {
        Toast.danger(
          "Your connection has dropped and you are now viewing this site in offline mode."
        );
      }

      // Update the ref with the current status
      wasOnline.current = !currentStatus;
    };

    // Add event listeners for online and offline events
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, [navigator.onLine]);

  return isOnline;
};
