import Alert from "components/Elements/Alert/Alert";
import { isSupported } from "firebase/messaging";
import { onMessages, requestPermission } from "lib/firebase";
import * as React from "react";

export const Notifications = () => {
  React.useEffect(() => {
    const initializeFirebaseMessaging = async () => {
      try {
        const supported = await isSupported();
        if (!supported) {
          console.error("Firebase Messaging is not supported in this browser.");
          return;
        }

        await requestPermission();

        // Set up a listener for incoming messages
        const unsubscribe = await onMessages((payload: any) => {
          Alert.info(payload?.data?.title);
        });

        // Clean up the listener on unmount
        return () => {
          if (unsubscribe) {
            unsubscribe();
          }
        };
      } catch (error) {
        console.error("Error initializing Firebase Messaging:", error);
      }
    };

    initializeFirebaseMessaging();
  }, []);

  return <div></div>;
};
export default Notifications;
